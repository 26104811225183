import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import _ from 'lodash'
import urljoin from 'url-join'
import store from 'client/redux/store'
import { IReduxState } from 'client/types'
import { MAINTENANCE_MODE_HEADER_NAME } from 'shared/constants/responseHeaders'

export const getFullUrl = (url: string) => {
  const state = store.getState() as IReduxState
  const { versionInfo } = state.versionData

  // Ignore calls starting with `/api` or `/auth`, as these don't need to be manipulated
  if (_.startsWith(url, '/api') || _.startsWith(url, '/auth')) {
    return url
  }

  const versionPart = versionInfo
    ? `/museum/${versionInfo.id}/version/${versionInfo.versionType}`
    : undefined

  const parts = _.compact(['/api', versionPart, url])
  const fullUrl = urljoin(...parts)

  return fullUrl
}

async function callAxios<T, D>(
  method: string,
  url: string,
  data: D | undefined,
  config: AxiosRequestConfig | undefined
): Promise<AxiosResponse<T, D>> {
  const fullUrl = getFullUrl(url)
  const optionalArgs = _.reject([data, config], _.isUndefined)
  const resp = await axios[method](fullUrl, ...optionalArgs)

  if (_.has(resp.headers, MAINTENANCE_MODE_HEADER_NAME)) {
    window.location.reload()
  }

  return resp
}

const httpGet = <T, D = any>(url: string, config?: AxiosRequestConfig) =>
  callAxios<T, D>('get', url, undefined, config)

const httpPost = <T, D = any>(url: string, data?: D, config?: AxiosRequestConfig) =>
  callAxios<T, D>('post', url, data, config)

const httpPut = <T, D = any>(url: string, data?: D, config?: AxiosRequestConfig) =>
  callAxios<T, D>('put', url, data, config)

const httpDelete = <T, D = any>(url: string, config?: AxiosRequestConfig) =>
  callAxios<T, D>('delete', url, undefined, config)

export default {
  get: httpGet,
  post: httpPost,
  put: httpPut,
  delete: httpDelete
}
