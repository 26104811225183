import * as yup from 'yup'
import { t } from 'client/i18n'
import _ from 'lodash'

export const NAME_CHAR_LIMIT = 32

const INVALID_LATITUDE_MESSAGE = `${t('Latitude')}: ${t(
  'Enter a valid latitude value in decimal degrees format (values range from -90 to 90).'
)}`
const INVALID_LONGITUDE_MESSAGE = `${t('Longitude')}: ${t(
  'Enter a valid longitude value in decimal degrees format (values range from -180 to 180).'
)}`

const INVALID_CAMERA_HEADING_MESSAGE = `${t('Map Orientation')}: ${t(
  'Enter a value between 0 and 360.'
)}`

const INVALID_MAP_TAB_ZOOM = `${t('Map Tab Zoom')}: ${t('Enter a positive value.')}`

const MISSING_LATITUDE_MESSAGE = `${t('Latitude')}: ${t(
  'To customize coordinates, both latitude and longitude are required.'
)}`

const MISSING_LONGITUDE_MESSAGE = `${t('Longitude')}: ${t(
  'To customize coordinates, both latitude and longitude are required.'
)}`

export const MapSchemaClient = yup.object().shape({
  cameraCenterLatitude: yup
    .number()
    .nullable()
    .min(-90, INVALID_LATITUDE_MESSAGE)
    .max(90, INVALID_LATITUDE_MESSAGE)
    .test({
      name: 'require-missing-lat',
      test: (value, context) => {
        const { cameraCenterLatitude, cameraCenterLongitude } = context.parent
        if (!_.isFinite(cameraCenterLatitude) && _.isFinite(cameraCenterLongitude)) {
          return context.createError({
            message: MISSING_LATITUDE_MESSAGE
          })
        }

        return true
      }
    }),
  cameraCenterLongitude: yup
    .number()
    .nullable()
    .min(-180, INVALID_LONGITUDE_MESSAGE)
    .max(180, INVALID_LONGITUDE_MESSAGE)
    .test({
      name: 'require-missing-long',
      test: (value, context) => {
        const { cameraCenterLatitude, cameraCenterLongitude } = context.parent
        if (_.isFinite(cameraCenterLatitude) && !_.isFinite(cameraCenterLongitude)) {
          return context.createError({
            message: MISSING_LONGITUDE_MESSAGE
          })
        }

        return true
      }
    }),
  cameraHeading: yup
    .number()
    .nullable()
    .min(0, INVALID_CAMERA_HEADING_MESSAGE)
    .max(360, INVALID_CAMERA_HEADING_MESSAGE),
  cameraZoom: yup.number().nullable().min(0, INVALID_MAP_TAB_ZOOM)
})

const SharedSchemaCore = {
  latitude: yup
    .number()
    .nullable()
    .required()
    .min(-90, INVALID_LATITUDE_MESSAGE)
    .max(90, INVALID_LATITUDE_MESSAGE),
  longitude: yup
    .number()
    .nullable()
    .required()
    .min(-180, INVALID_LONGITUDE_MESSAGE)
    .max(180, INVALID_LONGITUDE_MESSAGE)
}

export const BuildingSchemaCore = yup.object({
  name: yup.string().nullable().required()
})

export const BuildingFormSchemaClient = BuildingSchemaCore.shape(SharedSchemaCore)

export const PinSchemaCore = yup.object({
  name: yup.string().nullable().required().max(NAME_CHAR_LIMIT)
})

export const PinFormSchemaClient = PinSchemaCore.shape(SharedSchemaCore)
