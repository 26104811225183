import { useState, Dispatch, SetStateAction, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Formik, FormikProps } from 'formik'
import StandardForm, {
  SectionGroup,
  StyledSection
} from 'client/components/StandardForm/StandardForm'
import TextInputField from 'client/components/Formik/TextInput/TextInput'
import { IMuseumLocationJson } from 'shared/json/IMuseumLocationJson'
import FormErrorBanner from 'client/dsm/Banner/FormErrorBanner'
import * as yup from 'yup'
import styled from 'styled-components'
import Section from 'client/components/Form/Section/Section'
import useCoercedParam from 'client/hooks/useCoercedParam'
import useFormikErrors from 'client/hooks/useFormikErrors'
import DiscardChangesDialog from 'client/components/DiscardChangesDialog'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { refetchActiveQueries } from 'client/apollo'
import { useDelete, usePost, usePut } from 'client/hooks/api'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { useErrorDialog } from 'client/components/ErrorDialog'
import GooglePlacesAutoComplete from 'client/screens/AppEditor/About/LocationForm/GooglePlacesAutocomplete'
import { GQLMuseumLocation } from 'shared/graphql/types/graphql'
import { t } from 'client/i18n'
import HoursManagement from './HoursManagement/HoursManagement'
import DeleteLocationConfirmationDialog from '../DeleteLocationConfirmationDialog'

const HoursSection = styled(StyledSection)`
  margin-top: var(--spacing-small);
`

const LOCATIONS_QUERY = gql`
  query getLocations($museumId: Int!) {
    museum(id: $museumId) {
      id
      locations {
        id
        name
        placeId
        fullAddress
        days {
          id
          name
          open24Hours
          hours {
            id
            start
            end
          }
        }
      }
    }
  }
`

interface ILocationFormValues extends IMuseumLocationJson {
  displayHours: boolean
}

interface ILocationFormProps extends FormikProps<ILocationFormValues> {
  isEditing: boolean
  isLoading?: boolean
  onSave: () => void
  showDiscardChangesDialog: boolean
  setShowDiscardChangesDialog: Dispatch<SetStateAction<boolean>>
  showConfirmDeleteDialog: boolean
  setShowConfirmDeleteDialog: Dispatch<SetStateAction<boolean>>
  deleteLocation: () => Promise<any>
}

const LocationForm = (props: ILocationFormProps) => {
  const {
    values,
    isLoading,
    isEditing,
    onSave,
    setFieldValue,
    showDiscardChangesDialog,
    setShowDiscardChangesDialog,
    showConfirmDeleteDialog,
    setShowConfirmDeleteDialog,
    deleteLocation
  } = props

  const navigate = useNavigate()
  const onCancel = (formikBag: FormikProps<IMuseumLocationJson>) => {
    const { dirty } = formikBag
    if (dirty) {
      setShowDiscardChangesDialog(true)
    } else {
      navigate('..')
    }
  }

  const errorMap = useFormikErrors()
  const title = isEditing ? t('Edit Location') : t('Add Location')
  const onDelete = () => setShowConfirmDeleteDialog(true)

  return (
    <StandardForm
      title={title}
      onSave={onSave}
      onCancel={() => onCancel(props)}
      onDelete={isEditing ? onDelete : undefined}
      isLoading={isLoading}
      fullWidth={true}
    >
      <FormErrorBanner errorMap={errorMap} />

      <SectionGroup>
        <StyledSection>
          <Section title={t('Location')} />
          <TextInputField
            name="name"
            label={t('Name')}
            value={values.name}
            placeholder={t('e.g., Main Building, Events Pavilion')}
          />
          <GooglePlacesAutoComplete />
        </StyledSection>
        <HoursSection>
          <HoursManagement
            days={values.days}
            displayHours={values.displayHours}
            onUpdateDays={(newDays) => setFieldValue('days', newDays)}
          />
        </HoursSection>
      </SectionGroup>
      {showDiscardChangesDialog && (
        <DiscardChangesDialog
          onCancel={() => setShowDiscardChangesDialog(false)}
          onSave={() => navigate('..')}
        />
      )}
      {showConfirmDeleteDialog && (
        <DeleteLocationConfirmationDialog
          onCancel={() => setShowConfirmDeleteDialog(false)}
          onSave={deleteLocation}
        />
      )}
    </StandardForm>
  )
}

const DEFAULT_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
].map((name) => ({ name, hours: [], open24Hours: false }))

function getInitialValues(museumLocation: GQLMuseumLocation) {
  // Editing an existing location
  if (museumLocation) {
    const addressFields = _.pick(museumLocation, ['placeId', 'fullAddress'])
    return {
      name: museumLocation.name,
      days: !_.isEmpty(museumLocation.days) ? museumLocation.days : DEFAULT_DAYS,
      displayHours: !_.isEmpty(museumLocation.days),
      ...addressFields,
      // The United Kingdom is the only country in the countries list whose shortName is not its country code.
      // Currently, the country column in the DB stores the country code.
      country:
        museumLocation.countryShortName === 'UK' ? 'GB' : museumLocation.countryShortName || ''
    }
  }

  return {
    placeId: '',
    displayHours: false,
    days: DEFAULT_DAYS
  }
}

const VALIDATION_SCHEMA = yup.object().shape({
  placeId: yup.string().required().label('Address')
})

export default () => {
  const id = useCoercedParam<number>('id')

  const isEditing = !_.isNil(id)

  const dispatch = useDispatch()
  const [errorDialog, setError] = useErrorDialog()
  const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false)
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)

  const navigate = useNavigate()

  const { data, loading } = useQuery(LOCATIONS_QUERY)

  const museumLocation = _.find(data?.museum?.locations, { id })

  const initialValues = useMemo(() => getInitialValues(museumLocation), [museumLocation])

  // On successful create/update/delete operation
  const onSuccess = () => {
    navigate('..')
    refetchActiveQueries()
    dispatch(showChangesSavedToast())
  }

  const [createLocation, isCreatingLocation] = usePost('/locations', {
    onSuccess,
    onError: (error) => setError({ error, title: t('Unable to Add Location') })
  })

  const [updateLocation, isUpdatingLocation] = usePut(`/locations/${id}`, {
    onSuccess,
    onError: (error) => setError({ error, title: t('Unable to Edit Location') })
  })

  const [deleteLocation, isDeletingLocation] = useDelete(`/locations/${id}`, {
    onSuccess,
    onError: (error) => setError({ error, title: t('Unable to Delete Location') })
  })

  const handleSubmit = (values, formikProps) => {
    const { setSubmitting } = formikProps

    const actualValues = {
      ...values,
      // fullAddress is only in form state for display.  It should not be submitted to the server
      fullAddress: undefined,
      days: values.displayHours ? values.days : []
    }
    if (isEditing) {
      updateLocation(actualValues)
    } else {
      createLocation(actualValues)
    }
    setSubmitting(false)
  }

  const isLoading = loading || isCreatingLocation || isUpdatingLocation || isDeletingLocation

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={VALIDATION_SCHEMA}
    >
      {(formikProps) => (
        <>
          <LocationForm
            {...formikProps}
            isEditing={isEditing}
            isLoading={isLoading}
            onSave={formikProps.handleSubmit}
            showDiscardChangesDialog={showDiscardChangesDialog}
            setShowDiscardChangesDialog={setShowDiscardChangesDialog}
            showConfirmDeleteDialog={showConfirmDeleteDialog}
            setShowConfirmDeleteDialog={setShowConfirmDeleteDialog}
            deleteLocation={deleteLocation}
          />
          {errorDialog}
        </>
      )}
    </Formik>
  )
}
