import { useContext } from 'react'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationForm'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import _ from 'lodash'
import tusdUpload from 'client/util/tusdUpload'
import FileUploader, { FileUploadChangeEvent } from 'client/components/FileUploader/FileUploader'
import styled from 'styled-components'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import useField from 'client/hooks/useField'
import { delay } from 'shared/util/promise'
import { ReorderableSubtitlesRow } from 'client/components/ContentRow/ReorderableRow'
import { ExplanatoryText } from 'client/screens/Catalog/forms/VideoForm/styledComponents'
import { t } from 'client/i18n'
import resolveUnknownError from 'shared/util/resolveUnknownError'
import SubtitlesContextualHelp from './SubtitlesContextualHelp'

const StyledFileUploader = styled(FileUploader)`
  margin-top: var(--spacing-xsmall);
`

const StyleReorderableRowWrapper = styled(ReorderableSubtitlesRow)<{ hasError: boolean }>`
  border-color: ${({ hasError }) => (hasError ? 'var(--color-status-error)' : 'grey')};
  margin-top: var(--spacing-xsmall);
`
const SubtitlesFileField = () => {
  const { setLoadingStatus, onError, getFieldName } = useContext(TranslationFormContext)
  const subtitlesFileField = useField(getFieldName('subtitlesFile'))
  const { error, touched } = useField(getFieldName('subtitlesFile'))
  const subtitlesField = useField(getFieldName('videoSubtitles'))

  const isUploadingSubtitles = !_.isNil(subtitlesFileField.value)
  const hasSubtitles = isUploadingSubtitles || !_.isNil(subtitlesField.value)
  const subtitlesValue =
    (hasSubtitles && subtitlesFileField.value?.name) || subtitlesField.value?.fileName

  const onChange = async (event: FileUploadChangeEvent) => {
    const file = event.target.files![0]
    const objectUrl = URL.createObjectURL(event.target.files![0])

    setLoadingStatus(true)

    if (objectUrl) {
      URL.revokeObjectURL(objectUrl)
    }

    try {
      const url = await tusdUpload(file)
      subtitlesFileField.setValue({ name: file.name, url, file })
    } catch (e) {
      const err = resolveUnknownError(e)
      onError(err)
      // eslint-disable-next-line no-console
      console.error(err)
    } finally {
      setLoadingStatus(false)
    }
  }

  const onRemove = async () => {
    const field = isUploadingSubtitles ? subtitlesFileField : subtitlesField
    field.setValue(null, false)
    // Issue in formik - https://github.com/jaredpalmer/formik/issues/2083
    await delay(10)
    field.setTouched(true)
  }

  return (
    <FormField label={t('Subtitles/Captions')} additionalLabelNode={SubtitlesContextualHelp}>
      <ExplanatoryText>{t('Required file type: VTT.')}</ExplanatoryText>
      {hasSubtitles ? (
        <>
          <StyleReorderableRowWrapper
            showGrabber={false}
            isDragging={false}
            onRemove={onRemove}
            value={{ subtitlesValue }}
            hasError={!_.isNil(subtitlesFileField.error)}
          />
          {error && touched && <ErrorMessage name={getFieldName('subtitlesFile')} />}
        </>
      ) : (
        <StyledFileUploader
          name="subtitles"
          fileFormats={['.vtt']}
          buttonLabel={t('Add')}
          onChange={onChange}
        />
      )}
    </FormField>
  )
}

export default SubtitlesFileField
