import * as React from 'react'
import _ from 'lodash'
import { KeyboardEvent } from 'react'
import TextInputField, {
  IProps as IFormikTextInputProps
} from 'client/components/Formik/TextInput/TextInput'
import styled from 'styled-components'

const StyledFiniteNumberInputField = styled(TextInputField)`
  padding-left: var(--spacing-small);
  padding-right: var(--spacing-small);
`

type IFiniteNumberInputFieldProps = Omit<
  IFormikTextInputProps & { additionalLabelNode?: React.ElementType },
  'field' | 'form' | 'meta'
>

const FiniteNumberInputField = (props: IFiniteNumberInputFieldProps) => {
  // to ensure user can only input numbers excluding e.
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (_.includes(['e', 'E'], event.key)) {
      event.preventDefault()
    }
  }

  return <StyledFiniteNumberInputField {...props} type="number" onKeyDown={handleKeyDown} />
}

export default FiniteNumberInputField
