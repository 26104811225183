import styled from 'styled-components'
import {
  IFormFieldSectionProps,
  FormFieldSection
} from 'client/components/Form/FormField/FormFieldSection'

const Container = styled.div`
  margin-left: var(--spacing-small);
  margin-right: var(--spacing-small);
`

// Will consolidate the padding style into FormFieldSection eventually until we get rid of use of all existing StandardForm
export const EnhancedStandardFormFieldSection = (props: IFormFieldSectionProps) => {
  return (
    <Container>
      <FormFieldSection {...props} />
    </Container>
  )
}
