import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  isLoading as isLocationLoading,
  getMapById,
  isExteriorMap,
  getExterior
} from 'client/redux/selectors/maps'
import { useParams } from 'react-router-dom'
import { IBuildingsURLParams, IReduxState } from 'client/types'
import _ from 'lodash'
import { generateCoordinates } from 'shared/util/maps'
import {
  createBuildingFloorPin,
  createExteriorMapPin,
  updateBuildingFloorPinContent,
  updateExteriorMapPinContent,
  fetchPinContent
} from 'client/redux/actions/maps'
import { MAP_LOCATION_DEFAULT_RADIUS } from 'shared/constants/maps'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import useMapLocationContent from 'client/screens/AppEditor/MapEditor/useMapLocationContent'
import {
  LatitudeContextualHelp,
  LongitudeContextualHelp
} from 'client/screens/AppEditor/MapEditor/GoogleMapFields'
import { GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'
import { t } from 'client/i18n'
import ContextualHelp from 'client/components/ContextualHelp/ContextualHelp'
import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import useCoercedParam from 'client/hooks/useCoercedParam'
import { NAME_CHAR_LIMIT, PinFormSchemaClient, PinSchemaCore } from 'client/validation/Map'
import FiniteNumberInputField from 'client/components/Formik/TextInput/FiniteNumberInput'
import { StyledTextInputField } from 'client/components/Formik/TextInput/TextInput'
import { EnhancedStandardFormFieldSection } from 'client/components/StandardForm/EnhancedStandardFormFieldSection'
import useItemsAndExhibits from './useItemsAndExhibits'
import ReorderableLocationContentList from './ReorderableLocationContentList'

interface ILocationFormProps {
  plane: {
    width: number
    height: number
  }
}

interface ILocationFormWithItemsAndExhibitsProps extends ILocationFormProps {
  items: GQLItem[]
  exhibits: GQLExhibit[]
  isItemsAndExhibitsLoading: boolean
}

const LocationNameContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Location Name')}
    helpContent={
      <>
        <p>
          {t(
            'This name appears when you tap the pin on the map view and in the list view to reinforce the physical location.'
          )}{' '}
        </p>
        <p>
          {t(
            'You can enclose the name in square brackets to hide the name from the map (e.g., [Cafe]), but be aware that including a location name can help visitors find the content, especially visitors navigating via the list view.'
          )}
        </p>
      </>
    }
    tooltipContent={t('More on where the location name appears')}
  />
)

const LocationFormWithItemsAndExhibits = (props: ILocationFormWithItemsAndExhibitsProps) => {
  const { plane, items, exhibits, isItemsAndExhibitsLoading } = props
  const { floorId, locationId, buildingId: buildingIdParam } = useParams<IBuildingsURLParams>()

  const buildingId = Number.isNaN(Number(buildingIdParam)) ? null : Number(buildingIdParam)
  const mapId = _.toNumber(floorId)
  const pinId = _.toNumber(locationId)
  const dispatch = useDispatch()
  const isLocationDataLoading = useSelector(isLocationLoading)

  useEffect(() => {
    if (_.isFinite(mapId) && _.isFinite(pinId)) {
      dispatch(fetchPinContent(mapId, pinId))
    }
  }, [dispatch, mapId, pinId])

  const floors = useSelector((state: IReduxState) =>
    getMapById(state, { buildingId, floorId: mapId })
  )
  const locationInfo = _.find(floors?.mapLocations, { id: pinId })
  const isExterior = useSelector((state: IReduxState) => isExteriorMap(state, mapId))
  const {
    mapLocationContents,
    latitude: initialLatitude,
    longitude: initialLongitude,
    loading: isMapLocationContentsLoading
  } = useMapLocationContent(pinId)
  const isGoogleMap = useSelector(getExterior)?.isGoogleMap ?? false

  const isLoading =
    isLocationDataLoading || isItemsAndExhibitsLoading || isMapLocationContentsLoading

  const handleSubmit = (values) => {
    const { name, content: rawContent, latitude, longitude } = values
    const content = rawContent.map((contentArg) => ({
      exhibitId: contentArg.content.exhibit?.id,
      itemId: contentArg.content.item?.id,
      featured: contentArg.content.featured,
      updateAllMapLocations: contentArg.content.updateAllMapLocations
    }))

    const baseData = { name, content, latitude, longitude }
    if (locationInfo) {
      const locationData = { ...baseData, id: pinId }
      if (isExterior) {
        dispatch(updateExteriorMapPinContent(mapId, locationData))
      } else {
        dispatch(updateBuildingFloorPinContent(buildingId!, mapId, locationData))
      }
    } else {
      const { x, y } = generateCoordinates(plane)
      const hasExhibits = _.some(content, 'exhibitId')
      const radius = _.size(content) > 1 || hasExhibits ? MAP_LOCATION_DEFAULT_RADIUS : 0
      const pinData = { ...baseData, x, y, radius }

      if (isExterior) {
        dispatch(createExteriorMapPin(mapId, pinData))
      } else {
        dispatch(createBuildingFloorPin(buildingId!, mapId, pinData))
      }
    }
  }

  const content = locationInfo && !isLoading ? mapLocationContents : []

  const getLocationDescriptionText = () => {
    return isExterior && isGoogleMap
      ? t('You can set the pin’s location using latitude/longitude coordinates.')
      : t('You can set the pin’s location by dragging the pin on the map.')
  }

  const initialValues = {
    name: locationInfo?.name || '',
    content,
    latitude: initialLatitude,
    longitude: initialLongitude
  }

  const contentId = useCoercedParam<number>('locationId')

  const PinFormView = () => {
    return (
      <>
        <EnhancedStandardFormFieldSection
          label={t('Content')}
          description={t('A pin lets you attach content to a specific location on your map.')}
          hideDivider={true}
        >
          <ReorderableLocationContentList items={items} exhibits={exhibits} />
        </EnhancedStandardFormFieldSection>

        <EnhancedStandardFormFieldSection
          label={t('Location')}
          description={getLocationDescriptionText()}
        />
        <StyledTextInputField
          name="name"
          label={t('* Name')}
          placeholder={t('e.g., North side of reflecting pond, East Wing Gallery')}
          maxLength={NAME_CHAR_LIMIT}
          additionalLabelNode={LocationNameContextualHelp}
        />
        {isExterior && isGoogleMap && (
          <>
            <FiniteNumberInputField
              type="number"
              name="latitude"
              label={t('* Latitude')}
              additionalLabelNode={LatitudeContextualHelp}
            />
            <FiniteNumberInputField
              type="number"
              name="longitude"
              label={t('* Longitude')}
              additionalLabelNode={LongitudeContextualHelp}
            />
          </>
        )}
      </>
    )
  }
  return (
    <EnhancedStandardForm
      contentName="Pin"
      contentId={contentId}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      validationSchema={isGoogleMap && isExterior ? PinFormSchemaClient : PinSchemaCore}
    >
      <PinFormView />
    </EnhancedStandardForm>
  )
}

const LocationForm = (props: ILocationFormProps) => {
  const {
    items,
    exhibits,
    loading: isItemsAndExhibitsLoading,
    error: itemsAndExhibitsError
  } = useItemsAndExhibits()

  if (itemsAndExhibitsError) {
    return <GQLErrorRenderer error={itemsAndExhibitsError} />
  }

  return (
    <LocationFormWithItemsAndExhibits
      items={items}
      exhibits={exhibits}
      isItemsAndExhibitsLoading={isItemsAndExhibitsLoading}
      {...props}
    />
  )
}

export default LocationForm
