import { takeEvery } from 'redux-saga/effects'
import { SET_SELECTED_VERSION_INFO } from 'client/redux/actions/types'
import IVersionInfo from 'shared/VersionInfo'
import _ from 'lodash'
import { ISetSelectedVersionAction } from 'client/redux/actions/version'

function saveVersionInfoToLocalStorage(version: Partial<IVersionInfo>) {
  if (!version) {
    localStorage.removeItem('selectedVersion')
  } else {
    localStorage.setItem('selectedVersion', JSON.stringify(version))
  }
}

export function getVersionInfoFromLocalStorage() {
  const selectedVersionString = localStorage.getItem('selectedVersion')
  if (!selectedVersionString) {
    return null
  }

  return JSON.parse(selectedVersionString) as IVersionInfo
}

function handleSetVersionInfo(action: ISetSelectedVersionAction) {
  const { selectedVersionInfo, reload } = action

  const currentVersionInfo = getVersionInfoFromLocalStorage()
  if (!_.isEqual(selectedVersionInfo, currentVersionInfo)) {
    saveVersionInfoToLocalStorage(selectedVersionInfo)
    if (reload) {
      window.location.reload()
    }
  }
}

export default function* root() {
  yield takeEvery(SET_SELECTED_VERSION_INFO, handleSetVersionInfo)
}
