import { useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationForm'
import { ImageType } from 'shared/constants/images'
import { DefaultImageFormats } from 'client/constants'
import tusdUpload from 'client/util/tusdUpload'
import { ICropState } from 'shared/CropTypes'
import ImagePreview from 'client/screens/Catalog/forms/ImageForm/ImagePreview'
import {
  PreviewContainer,
  PreviewImages
} from 'client/screens/Catalog/forms/ImageForm/styledComponents'
import ImageCropForm from 'client/screens/Catalog/forms/ImageCropForm/ImageCropForm'
import FileUploader, { FileUploadChangeEvent } from 'client/components/FileUploader/FileUploader'
import { t } from 'client/i18n'
import ImageFileMenuPopout from 'client/screens/Catalog/forms/ImageForm/ImageFileMenuPopout'
import resolveUnknownError from 'shared/util/resolveUnknownError'
import ProcessingErrorMessage from '../shared/ProcessingErrorMessage'

/**
 * NOTE:
 *
 * Ideally, we would render all pre-existing image previews with the same fullUrl source, and if there was cropState in the DB, we would use this to render the preview in real time.
 *
 * However, legacy images that have been cropped, may have no cropState in the DB.
 *  - Images that have NOT been cropped also have no cropState in the DB.
 *  - THERE IS NO WAY TO DISCERN THE DIFFERENCE BETWEEN LEGACY-CROPPED, AND INTENTIONALLY NOT CROPPED IMAGES.
 *
 * Unfortunately it means the following:
 *  - We will always have to use the thumbnail and hero url as the source for previews of pre-existing images.
 *  - If the user makes a cropping update in the form, we will have to change the source of the image in the preview from the cropped thumbnail/hero url, to a source url (fullUrl) that gives us access to the entire image. This is because we are rendering the crop updates in the browser in real-time.
 *  - We will have to fetch the original naturalWidth of the source image to handle updating the ratio for fullUrls that exceed the megapixel max. We should be able to just use cropState, but because it may not always exist, we need to explicitly fetch the width.
 */

const ImagePreviewDimensions = {
  THUMBNAIL: { width: 168, height: 168 },
  HERO: { width: 299, height: 168 }
}

interface IImageFileFieldProps {
  imageId: number | undefined | null
  name: string
  source: string
  full: string
  thumbnail: string
  hero: string
}

const ImageFileField = ({
  name,
  imageId,
  source: sourceUrl,
  full: fullUrl,
  thumbnail: thumbnailUrlOriginal,
  hero: heroUrlOriginal
}: IImageFileFieldProps) => {
  const navigate = useNavigate()

  const { setLoadingStatus, setFieldValue, getFieldValue, onError } =
    useContext(TranslationFormContext)
  // represents crop updates made during form session (not pre-existing crop state in DB)
  const [cropState, setCropState] = useState<ICropState | null>(null)
  // url of an uploaded image file (new image)
  const [objectUrl, setObjectUrl] = useState<string>()

  const hiddenImageFileInputRef = useRef<HTMLInputElement>(null)
  const hiddenSourceImage = useRef<HTMLImageElement>(null)

  const localFile = getFieldValue('file')

  useEffect(() => {
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl) // properly revoke object urls for performance reasons
      }
    }
  }, [objectUrl])

  useEffect(() => {
    if (!_.isEmpty(cropState)) {
      setFieldValue('cropState', cropState)
    }
    // TODO this seems to work as we have it, but the linter complains that we're missing the deps
    // commented out below. Simply adding those deps causes an infinite loop here because setFieldValue
    // gets redefined every time we call it, so we'll leave this for now and come back to it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropState /* , setFieldValue */])

  // Prioritize new image upload source which is generated via URL.createObjectURL. (new image)
  // Otherwise, if cropping has occurred, use the fullUrl in order to render the cropped image in real-time. (pre-existing Image)
  // Finally, if no cropping has occurred, use the original thumbnail/hero urls. (pre-existing Image)
  const thumbnailUrl = objectUrl || (cropState?.thumbnail ? fullUrl : thumbnailUrlOriginal)
  const heroUrl = objectUrl || (cropState?.hero ? fullUrl : heroUrlOriginal)

  const onChange = async (event: FileUploadChangeEvent) => {
    const file = event.target.files![0]

    if (file) {
      const url = URL.createObjectURL(file)
      // Down the road we should allow a multi-submit action to avoid a blocking loading state here;
      // It would mean uploading to tusd, waiting for the response, then POSTing the mutation.
      setLoadingStatus(true)

      try {
        const tusdUrl = await tusdUpload(file)
        // only set this if tusd upload was successful; otherwise the form will preview the image, but the mutation will inevitably fail
        setObjectUrl(url)
        setFieldValue('file', { name: file.name, url: tusdUrl })
        setCropState(null)
      } catch (e) {
        const err = resolveUnknownError(e)
        onError(err)
        // eslint-disable-next-line no-console
        console.error(err)
      } finally {
        setLoadingStatus(false)
      }
    }
  }

  const navigateToCropper = (type: ImageType) => navigate(`${type}/crop`)

  const fieldContent = _.isNil(thumbnailUrl || heroUrl) ? (
    <FileUploader
      name="mediaFileInput"
      buttonLabel={t('Add Image File')}
      fileFormats={DefaultImageFormats}
      onChange={onChange}
    />
  ) : (
    <PreviewContainer>
      <input
        ref={hiddenImageFileInputRef}
        name="hiddenImageFileInput"
        type="file"
        accept={DefaultImageFormats.join(', ')}
        onChange={onChange}
        hidden={true}
      />
      {/* Note:
        The width column was removed from the Image model in https://bbgithub.dev.bloomberg.com/foundation/docent-cms/pull/3859 and https://bbgithub.dev.bloomberg.com/foundation/docent-cms/pull/3860.
        This hidden image element exists only so we can extract the naturalWidth value that gets passed to the ImagePreview component.
       */}
      <img ref={hiddenSourceImage} src={objectUrl || sourceUrl} hidden={true} />
      <PreviewImages>
        <ImagePreview
          label={t('Edit Thumbnail')}
          onClick={() => navigateToCropper(ImageType.THUMBNAIL)}
          url={thumbnailUrl}
          cropInfo={cropState?.thumbnail!}
          sourceWidth={hiddenSourceImage.current?.naturalWidth!}
          dimensions={ImagePreviewDimensions.THUMBNAIL}
        />
        <ImagePreview
          label={t('Edit Hero Image')}
          onClick={() => navigateToCropper(ImageType.HERO)}
          url={heroUrl}
          cropInfo={cropState?.hero!}
          sourceWidth={hiddenSourceImage.current?.naturalWidth!}
          dimensions={ImagePreviewDimensions.HERO}
        />
      </PreviewImages>
      <ImageFileMenuPopout
        id={imageId}
        localFile={localFile}
        onReplace={() => hiddenImageFileInputRef.current!.click()}
        onEdit={navigateToCropper}
      />
    </PreviewContainer>
  )

  return (
    <>
      {fieldContent}
      <ProcessingErrorMessage name={name} contentName="image" />
      <Routes>
        <Route
          path=":imageType/crop"
          element={
            <ImageCropForm
              objectUrl={objectUrl}
              cropState={cropState!}
              applyCropState={setCropState}
            />
          }
        />
      </Routes>
    </>
  )
}

export default ImageFileField
