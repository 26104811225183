import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IMapJson } from 'shared/json/IMapJson'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import { updateExteriorMap } from 'client/redux/actions/maps'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import styled from 'styled-components'
import { EnhancedStandardFormFieldSection } from 'client/components/StandardForm/EnhancedStandardFormFieldSection'
import { MapSchemaClient } from 'client/validation/Map'
import useField from 'client/hooks/useField'
import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import FiniteNumberInputField from 'client/components/Formik/TextInput/FiniteNumberInput'
import {
  LatitudeContextualHelp,
  LongitudeContextualHelp,
  MapOrientationContextualHelp
} from './GoogleMapFields'

const StyledToggleField = styled(ToggleField)`
  padding-left: var(--spacing-small);
`

const GoogleMapsForm = ({ loading, map }: { loading: boolean; map?: IMapJson }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EDIT_GOOGLE_MAPS_INFO } = useFeatureFlags()

  const onSubmit = (values) => {
    dispatch(updateExteriorMap(values))
    navigate('..')
  }

  const initialValues = {
    isGoogleMap: map?.isGoogleMap,
    cameraCenterLatitude: map?.cameraCenterLatitude,
    cameraCenterLongitude: map?.cameraCenterLongitude,
    cameraHeading: map?.cameraHeading,
    cameraZoom: map?.cameraZoom
  }

  const MapFormView = () => {
    const { value: isGoogleMap } = useField('isGoogleMap')
    return (
      <>
        {EDIT_GOOGLE_MAPS_INFO && (
          <StyledToggleField name="isGoogleMap" label={t('Google Maps Enabled')} />
        )}

        {isGoogleMap && (
          <>
            <EnhancedStandardFormFieldSection label={t('Center Point Location')} />
            <FiniteNumberInputField
              name="cameraCenterLatitude"
              label={t('Latitude')}
              additionalLabelNode={LatitudeContextualHelp}
            />
            <FiniteNumberInputField
              name="cameraCenterLongitude"
              label={t('Longitude')}
              additionalLabelNode={LongitudeContextualHelp}
            />

            <EnhancedStandardFormFieldSection />
            <FiniteNumberInputField
              name="cameraHeading"
              label={t('Map Orientation')}
              additionalLabelNode={MapOrientationContextualHelp}
            />
            <FiniteNumberInputField
              name="cameraZoom"
              label={t('Map Tab Zoom')}
              description={t(
                'The zoom level for the guide’s Map tab is dynamic and scales automatically to ensure that all pins are visible on all devices. Changing the zoom here might mean some pins do not appear in the default view.'
              )}
            />
          </>
        )}
      </>
    )
  }

  return (
    <EnhancedStandardForm
      contentName="Map"
      contentId={map?.id}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isLoading={loading}
      validationSchema={MapSchemaClient}
    >
      <MapFormView />
    </EnhancedStandardForm>
  )
}

export default GoogleMapsForm
